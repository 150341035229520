//@import "~react-web-bot/src/styles";

@import "~react-web-bot/src/variables";
@import "../styles/variables";

@import "~@material/ripple/mixins";
@import "~@material/elevation/mixins";
@import "~@material/elevation/variables";
@import "~@material/animation/variables";
@import "~@material/button/mdc-button";
@import "~@material/card/mdc-card";
@import "~@material/textfield/mdc-text-field";
@import "~@material/typography/mdc-typography";
@import "~@material/theme/mdc-theme";



@import "~react-web-bot/src/components/chat-balloon";
@import "~react-web-bot/src/components/chat-form";
@import "~react-web-bot/src/components/web-bot";
//@import "~react-web-bot/src/components/web-bot__icon";
//@import "~react-web-bot/src/components/web-bot__history";

// Make the underline more visible
.chat-form {
  .mdc-text-field, .mdc-text-field__input {
    height: 24px;
    padding: 0;
  }

  .mdc-text-field__input {
    &:focus {
      border-bottom: 1px solid $chat-input-focus !important;
    }

    &:not(:focus) {
      border-bottom: 1px solid $chat-input-not-focus !important;
    }
  }
}

/*
.web-bot__icon {
  border: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  margin: 0px;
  padding: 12px;
  outline: none;
  position: relative;
  z-index: 1;
  overflow: visible;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background: none;
}*/
