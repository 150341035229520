@import "styles/variables";
@import "prompts/form";

html, body {
  height: 100%;
}

body {
  margin: 0;
  /*padding: 0;*/
  font-family: 'Roboto', 'Noto', sans-serif;
  font-size: $body-font-size;
  line-height: 1.5;

  //display: flex;
  //flex-direction: column;
  //min-height: 100vh;
  //color: #372828;
  //background: #feba00 url("/honeycomb.png") repeat;
  /*background: -moz-radial-gradient(center, ellipse cover,  #ffc107 0%, #feba00 100%); !** FF3.6-15 *!*/
  /*background: -webkit-radial-gradient(center, ellipse cover,  #ffc107 0%,#feba00 100%); !** Chrome10-25,Safari5.1-6 *!*/
  //background: url("/honeycomb.png") repeat, radial-gradient(ellipse at center, #feba00 0%,#fbb400 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

body.swiping {
  user-select: none;
}

$footerHeight: 2.5rem;

#root {
  //position: relative;
  //min-height: 100vh;
  //width: 100%;
  display: flex;
  flex-direction: column;
}
#content-wrap {
  //padding-bottom: $footerHeight;    /* Footer height */
  //flex: 1 0 auto;

  //body.with-map & {
  //  //padding-bottom: 0;
  //}

  position: relative;
  flex: 1 0 auto;
  background: url(/images/honeycomb.svg) repeat, linear-gradient(#feba00 30%,#ff8a01 100%);
}

#footer {
  flex-shrink: 0;
  //height: $footerHeight * 2;
  line-height: $footerHeight;
  text-align: center;
  //position: absolute;
  //bottom: 0;
  //left: 0;
  //right: 0;
  color: #ffc838;
  background: #ff8a01;

  a {
    color: #ffdd80;
  }

  ul {
    display: inline;
    padding: 0;
  }
  li {
    display: inline-block;
    margin-left: 12px;

    &:first-child {
      margin-left: 0;
    }
  }

  //body.with-map & {
  //  height: 0;
  //}
}

@media (max-width: 500px) {
  #footer {
    //height: $footerHeight * 2;
  }
}

@media (min-width: 500px) {
  #footer {
    //height: $footerHeight * 2;
    div {
      display: inline;
      margin-left: 50px;
    }
  }
}

a {
  cursor: pointer;
}
a, a.active:hover {
  color: $accent-color;
  text-decoration: none;
}
a:hover {
  //color: #FFC107;
  text-decoration: underline;
}

.unboxedX.rotated {
  display: inline-block;
  transform: translate(-.05em,-.15em) rotate(-20deg);

  a:hover & {
    display: inline;
  }
}
