header h1, .dubbie-bee-font {
  font-family: 'comfortaabold', 'Roboto', 'Noto', sans-serif;
}

header {
  //flex-grow: 1;

  h1 {
    cursor: pointer;
    display: inline-block;
    margin: 0 12px 0 0;
    font-size: 24px;
  }

  .grow {
    flex-grow: 1;
  }

  button {
    vertical-align: middle;
  }

  .user-icon {
    img {
      margin: -12px;
      border-radius: 24px;
      width: 48px;
      height: 48px;
    }
  }
}
