
.sign-in-button {
  background-color: #fff;
  text-transform: none !important;
  padding-left: 1px !important;
  padding-right: 8px !important;
  justify-content: left !important;
  padding: 1px 8px 1px 1px !important;
}

.sign-in-button__icon {
  padding-left: 4px;
  padding-right: 11px;
  height: 32px;

  svg {
    margin-top: 4px;
  }
}


.sign-in-button__Slack {
  background-color: #fff !important;
}

.sign-in-button__Facebook {
  color: #fff !important;
  background-color: #4267b2 !important;
}

.sign-in-button__Google {
  .sign-in-button__icon {
    background-color: #fff;
    padding: 0;
    width: 32px;
    border-radius: 3px;
    margin-right: 6px;

    svg {
      margin-top: 7px;
    }
  }

  &.sign-in-button__dark {
    color: #fff !important;
    background-color: #4285f4 !important;
  }
}

.sign-in-button__LinkedIn {
  color: #fff !important;
  background-color: #0077b5 !important;
}
