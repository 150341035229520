$app-primary-color: #372828;
$app-secondary-color: #FFC107;

/*    --primary-color: #FFC107;
    --primary-color: #FFC107;
    --primary-text-color: #FFC107;
    --secondary-text-color: #FFC107;*/

$background-color:         #FBB400;
$dark-primary-color:       #FFA000;
$default-primary-color:    #FFC107;
$light-primary-color:      #FFECB3;
$text-primary-color:       #3E2723;
$accent-color:             #795548;
$primary-background-color: #FFF8E1;
$primary-text-color:       #3E2723;
$secondary-text-color:     #4E342E;
$disabled-text-color:      #A1887F;
$divider-color:            #D7CCC8;

$mic-color: $default-primary-color;

$mdc-theme-primary: $primary-text-color;
$mdc-theme-accent: $accent-color;
$mdc-theme-background: $background-color;
$chat-input-focus: darken($mic-color, 0.9);
$chat-input-not-focus: $mic-color;

// Google recommend 14dp, seems very small though...
$body-font-size: 16px;