@import "../styles/variables";

.chat-form, .chat-balloon {
  .vertical q {
    display: block;
  }

  q {
    margin-bottom: 8px;
  }

  q::before, q::after {
    display: inline-block;
    content: "\201C";
    font-family: Georgia, serif;
    font-size: 60px;
    font-weight: bold;
    color: $default-primary-color;
    vertical-align: middle;
    line-height: 0;
    top: 8px;
    position: relative;
  }
  q::before {
    padding-right: 8px;
  }
  q::after {
    content: "\201D";
    padding-left: 8px;
  }
}

.chat-form {
  small {
    color: $accent-color;
    display: block;
    text-align: center;
  }

  /*paper-button.quote {
      border: 1px solid $default-primary-color;
      padding-left: 0;
      padding-right: 0;
      border-top-left-radius: 14px;
      border-bottom-right-radius: 14px;
  }
  paper-button.quote q {
      margin: 0;
  }
  paper-button.quote q::before {
      margin-left: -3px;
      position: relative;
      top: -5px;
  }
  paper-button.quote q::after {
      top: 24px;
      margin-right: -3px;
      position: relative;
  }*/

  //paper-button {
  //  color: $app-primary-color;
  //  background-color: $light-primary-color;
  //}

  .notes {
    background: $light-primary-color;
    margin: 8px -16px -16px;
    padding: 16px;
    border-radius: 0 0 10px 10px;
  }
}
